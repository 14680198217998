import * as React from "react";
import { SVGProps } from "react";
import { useTheme } from "styled-components";

const NavbarSearchIcon = ({ color, ...props }: SVGProps<SVGSVGElement>) => {
  const { colors } = useTheme();
  let strokeColor = color;

  if (!color) {
    strokeColor = colors.secondary.normal;
  }

  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.2821 31.2664L24.1014 25.0858M16.0715 28.4243C19.0866 28.4243 21.9782 27.2266 24.1102 25.0946C26.2422 22.9626 27.44 20.071 27.44 17.0559C27.44 14.0408 26.2422 11.1492 24.1102 9.01723C21.9782 6.88524 19.0866 5.6875 16.0715 5.6875C13.0565 5.6875 10.1649 6.88524 8.03286 9.01723C5.90087 11.1492 4.70313 14.0408 4.70312 17.0559C4.70313 20.071 5.90087 22.9626 8.03286 25.0946C10.1649 27.2266 13.0565 28.4243 16.0715 28.4243Z"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ stroke: strokeColor }}
      />
    </svg>
  );
};

export default NavbarSearchIcon;
